import Hotline from "../images/phone2.png";
import Logo from "../images/tsl_logo2.png";
import { Link } from 'react-router-dom';

function Menu(){
    return(
        <div className="menu d-flex" style={{backgroundColor:"rgba(255,255,255,0.95)"}}>
            <div>
                <nav>
                    <ul className="horizontal-menu">
                        <li>
                        <img src={Logo} alt="" width="120px" />
                        </li>
                        <li>
                        <Link to="/">Home</Link>
                        </li>
                        <li>
                        <Link to="/about">About Us</Link>
                        </li>
                        <li>
                        <Link to="/services">Our Services</Link>
                        </li>
                        <li>
                        <Link to="/blogs">Blogs</Link>
                        </li>
                        <li>
                        <Link to="/contact">Contact Us</Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div>
                <img src={Hotline} alt="Travel Solutions Hotline" width="50px" className="pl-5" />
                <span style={{fontSize:"20px", fontWeight:"700", color:"blue"}}>+234 803 304 3856</span>
            </div>
        </div>
    );
}
export default Menu;