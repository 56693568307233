import Phone from "../images/support.png";
import Email from "../images/email.png";
import Address from "../images/address.png";

export default function Contactinfo(){
    return(
        <div className="p-5 cont">
            <p className="text-center">Catch Us Here</p>
            <h2 className="text-center pb-5 mt-2" style={{fontWeight:"700", fontSize:"50px"}}>We'd love to
            hear <br /> from you something</h2>    

            <div className="row p-3">

                <div className='col-sm-4 mt-3'>
                    <div style={{height: "300px",maxWidth: "400px", padding: "10px 0px", textAlign: "center"}} className="contact boxshadow p-5">
                        <img src={Phone} alt="Travel Solution Ltd" width="100px" />
                        <h3>Our Numbers</h3>
                        <span>+234 803 304 3856</span><br />
                        <span>+234 803 335 6162</span>
                    </div> 
                </div>

                <div className='col-sm-4 mt-3'>
                    <div style={{height: "300px", maxWidth: "400px", padding: "10px 0px", textAlign: "center"}} className="contact boxshadow p-5">
                        <img src={Email} alt="Travel Solution Ltd" width="100px" />
                        <h3>Email Address</h3>
                        <span>info@travelsolutionsltdng.com</span><br />
                        <span>support@travelsolutionsltdng.com</span><br /><br />
                    </div>
                </div>

                <div className='col-sm-4 mt-3'>
                    <div style={{height: "300px", maxWidth: "400px", padding: "10px 0px", textAlign: "center"}} className="contact boxshadow p-5">
                        <img src={Address} alt="Travel Solution Ltd" width="100px" />
                        <h3>Our Location</h3>
                        <p>60, Ilupeju Way, Dolphin Estate, Ikoyi, Lagos, Nigeria.</p>
                    </div> 
                </div>

            </div>
        </div>
    );
}