import plane from '../images/aeroplane4.png';

export default function Quote(){
    return(
        <div className="getintouch-bg">
            <div className="row getintouch-bg-inner boxshadow">
                <div className="col-sm-5">
                    <p>Get In Touch</p>
                    <br /> 
                    <h1>Looking for the experienced<br /> travel agency services </h1>
                    <p style={{color: "yellow", fontWeight: "700"}}>You are just in the right place...</p>
                </div>
                <div className="col-sm-4" style={{justifyContent: 'center'}}>
                    <img id="airplane" src={plane} alt="Travel Solutions Limited" width="100%" />
                </div>
                <div className="col-sm-3 getintouch-bg-inner-column2">
                    <h1 style={{textAlign:'center', fontWeight:'bolder',fontSize:'50px'}}>Travel Solutions <span className="text-dark">Serves Better</span></h1>
                </div>
            </div>
        </div>
    );
}