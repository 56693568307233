//import Briefcase from "../images/briefcase.jpg";

function Travelling (){
    return(
        <div className="row bg-light travel" style={{}}>
            <div className="col-sm-7">
                
            </div>

            <div className="col-sm-5 p-3" style={{backgroundColor:"rgba(122, 195, 221, 0.8)", padding:"50px"}}>
                <h2 className="mt-5">
                <span style={{color: "white", fontSize: "80px"}}>Travel Solutions Limited</span>
                </h2>
                <h4 className="text-center text-black mb-5" style={{fontWeight:"700"}}>We Make Your Trips Amazing &
                Memorable! Contact us today. You can speak with any member of our Team.</h4>
            </div>

            
        </div>
    );
}
export default Travelling;