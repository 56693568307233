import Getintouch from '../components/getintouch.js';
import Team from '../components/team';
import Menu from '../components/menu';
import Partners from '../components/partners.js';

export default function About(){
    return (
        <>
            <div className="sbanner">
                <div className="sbanner-inner p-5">
                    <Menu />
                    <h2 className="p-5 text-white" style={{fontWeight:"700"}}>About Us</h2>  
                </div>
            </div>
            <Team />
            <Partners />
            <Getintouch />
        </>
    );
}