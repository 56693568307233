import Header from '../components/header';
import Getintouch from '../components/getintouch.js';
import About2 from '../components/about2';
import Services from '../components/services';
import Testimony from '../components/testimony';
import CTO from '../components/calltoaction.js';
import Travelling from '../components/travelling.js';
import Partners from '../components/partners.js';

export default function Home(){
    return (
        <div>
            <Header />
            <Services />
            <About2 />
            <Travelling />
            <CTO />
            <Testimony />
            <Partners />
            <Getintouch />
        </div>
    );
}