import Getintouch from '../components/getintouch';
//import Service from '../components/services';
import Menu from '../components/menu';
import Testimony from '../components/testimony';
import Family from '../components/mainservices.js';

export default function Services(){
    return (
        <div>
            <div className="sbanner">
                <div className="sbanner-inner p-5">
                    <Menu />
                    <h2 className="p-5 text-white" style={{fontWeight:"700"}}>Our Services</h2>  
                </div>
            </div>
            <Family />
            <Testimony />
            <br />
            <Getintouch />
            
        </div>
    );
}