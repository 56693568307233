import Officeimage from '../images/office.png';
//import React, { useRef } from 'react';
//import emailjs from '@emailjs/browser';

export default function Contactus(){
/*
    const nodemailer = require("nodemailer");

let sender = nodemailer.createTransport({
	service: 'gmail',
	auth: {
		user: 'username@gmail.com',
		pass: 'your_password'
	}
});

let mail = {
	from: "username@gmail.com",
	to: "receiver's_username@gmail.com",
	subject: "Sending Email using Node.js",
	text: "That was easy!"
};

sender.sendMail(mail, function (error, info) {
	if (error) {
		console.log(error);
	} else {
		console.log("Email sent successfully: "
			+ info.response);
	}
});



    const form = useRef();

    const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_0ox5w88', 'template_4evfj4b', form.current, {
        publicKey: 'Lrh-FA4U_PX3UJzr6',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
    }
*/
    return(
        <div className="row m-5">

            <div className="col-sm-6">
                <form className="p-3">
                    <p>Make Custom Request</p>
                    <h1 className="contact-header">Tell <span style={{color:"blue"}}>Travel Solutions </span>about your Trip</h1>
                    <div class="row">
                        <div class="col-sm-6 mt-5">
                            <input type="text" id="user_name" name="user_name" placeholder="Fullname" className="form-control form-control-lg contactDivs" />
                        </div>

                        <div class="col-sm-6 mt-5">
                            <input type="text" id="user_email" name="user_email" placeholder="Email" className="form-control form-control-lg contactDivs" />
                        </div>   
                    </div>

                    <div class="row">
                        <div class="col-sm-6 mt-5">
                            <input type="text" id="budget" name="budget" placeholder="Budget" className="form-control form-control-lg contactDivs" />
                        </div>

                        <div class="col-sm-6 mt-5">
                            <input type="text" id="subj" name="subj" placeholder="Subject" className="form-control form-control-lg contactDivs" />
                        </div>   
                    </div>

                    <div class="row mt-5">
                        <div class="col-sm-12">
                            <textarea id="message" name="message" placeholder="Message" className="form-control form-control-lg contactDivs">
                            </textarea>
                        </div> 
                    </div>

                    <div className="row mt-5">
                        <div className="col-sm-4">
                            <input type="submit" id="submit" name="submit" value="Get a Quote" className="form-control form-control-lg bg-warning" />
                        </div>
                    </div>
                </form>
            </div>

            <div className="col-sm-6 bg-light">
                <img src={Officeimage} width="100%" alt="contact" />
            </div>           
        </div>
    );
}
