import Getintouch from '../components/getintouch';
import Contact from '../components/contactinfo';
import Msend from '../components/contactus';
import Menu from '../components/menu';

export default function Contactus2(){
    return (
        <div>
            <div className="sbanner">
                <div className="sbanner-inner p-5">
                    <Menu />
                    <h2 className="p-5 text-white" style={{fontWeight:"700"}}>Contact Us</h2>  
                </div>
            </div>

            <Contact />
            <Msend />
            <Getintouch />
            
        </div>
    );
}