import React from 'react';
import './App.css';
import Home from './pages/home';
//import Sendmail from './components/sendemail.js';
//import Info from './components/contactinfo.js';
//import CTO from './components/calltoaction.js';
//import Header from './components/header';
import Footer from './components/footer';
import Services from './pages/services';
import About from './pages/about';
import Contact from './pages/contactus2';
import Blogs from './pages/blogs';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';

function App() {

  const Layout =()=>{
    return(
      <>
        <Outlet />
        <Footer />
      </>
    );
  }

  return (
    <>
      <title>Travel Solutions Limited - Best way to travel...</title>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="services" element={<Services />} />
              <Route path="blogs" element={<Blogs />} />
              <Route path="contact" element={<Contact />} />
            </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
