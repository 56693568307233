import Office from '../images/family5.jpg';

export default function Mainservices(){
    return(
        <div className="row bg-primary" style={{marginTop: "100px"}}>
            
            <div className="col-sm-6 p-5">
                <h2 className="pb-5" style={{fontSize:"50px", textAlign:"center", fontWeight:"700",color:"white"}}>Our Services</h2>
                <ul className="serve" style={{fontFamily: "cursive", color:"white", fontSize: "40px", fontWeight:"700", textDecoration:""}}>
                    <li>Ticket Booking/Reservation</li>
                    <li>Hotel Reservation</li>
                    <li>Trip Planning and Advice</li>
                    <li>Source Affordable Airliner</li>
                    <li>Visa Processing Tips</li>
                </ul>
            </div>

            <div className="col-sm-6">
                <img src={Office} alt="Our Services" width="100%" />
            </div>

        </div>
    );
}