import Traveller from '../images/family3.jpg';
import About1 from '../images/trust1.png';
import Award1 from '../images/award1.png';

export default function About(){
    return(
        <div className="abt">
            <div className="row">

                <div className="col-sm-6 p-5">
                    <img src={Traveller} width="100%" alt="Travellers" />
                </div>

                <div className="col-sm-6 p-5 about">
                    <button className="boxshadow p-2">About Our Company</button>

                    <h2>Choose The Best <span>Travel Agency</span> For Your Trip</h2>
                    <br />
                    <p style={{borderLeft: "5px solid black", padding: "30px"}} className="bg-white">At Travel Solutions, we assist our clients to secure their flight tickets even at the shortest time possible. We go extra to make sure your journey is hassle-free and pretty seamless. You can count us.</p>
                    <br />
                    <div className="row bg-white text-center" style={{borderTop: "1px solid blue", borderBottom: "1px solid blue"}}>
                        <div className="col-sm-6 p-4" style={{display:"flex"}}>
                            <img src={About1} alt="travel solutions services" width="50%"  />
                            <h4 style={{width:"50%", paddingTop:"50px"}}>Integrity</h4>
                        </div>

                        <div className="col-sm-6 p-4 text-center" style={{display:"flex"}}>
                            <img src={Award1} alt="travel solutions services" width="50%" />
                            <h4 style={{width:"50%", paddingTop:"30px"}}>Award Winning</h4>
                        </div>
                    </div>
                    <br />
                    <button type="button" className="btn bg-primary p-2 boxshadow text-white">Contact Us</button>
                </div>

            </div>
        </div>
    );
}