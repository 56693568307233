function CallToAction(){
    return(
        <div className="call">
            <div className="call-head p-5" style={{backgroundColor:'rgba(0,0,0, 0.3)',textAlign:'center'}}>
                <p className="mt-5">Global Clients From Around The World</p>
                <h2 className="pb-5">We Make Every <br />Journey Hassle-free.</h2>

                <button type="button" className="btn btn-danger form-control-lg m-2">Join Our Community</button>
                <button type="button" className="btn btn-danger form-control-lg m-2">Contact Us Today</button>
            </div>
        </div>
    );
}
export default CallToAction;