import Service1 from "../images/flight.png";
import Service2 from "../images/fast.png";
import Service3 from "../images/hotel3.png";
import Service4 from "../images/customers.png";
import Service5 from "../images/passport1.png";

function Services(){
    return(
        <div style={{backgroundImage:"url(/images/crowd3a.jpg)"}}>
            <div style={{backgroundColor:"rgba(0, 0, 255, 0.6)", padding:"50px"}}>
            <div className="row service" style={{paddingTop:"10px"}}>
                <div className="col-sm-2 box boxshadow text-center pt-5 pb-5">
                    <img src={Service1} alt="travel solutions services" width="50%" />
                    <h3>Affordable Booking <br />Flight</h3>
                </div>
                <div className="col-sm-2 box boxshadow text-center pt-5 pb-5">
                    <img src={Service3} alt="travel solutions services" width="50%" />
                    <h3>Hotel <br />Reservation</h3>
                </div>
                <div className="col-sm-2 box boxshadow text-center pt-5 pb-5">
                    <img src={Service2} alt="travel solutions services" width="50%" />
                    <h3>Trip Plans/<br />Advice</h3>
                </div>
                <div className="col-sm-2 box boxshadow text-center pt-5 pb-5">
                    <img src={Service4} alt="travel solutions services" width="50%" />
                    <h3>Customer <br />Satisfaction</h3>
                </div>
                <div className="col-sm-2 box text-center boxshadow pt-5 pb-5">
                    <img src={Service5} alt="travel solutions services" width="50%" />
                    <h3>Passport/Visa <br /> Processing</h3>
                </div>
        
            </div>
            </div>
        </div>
    );
}
export default Services;