import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Partners(){
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000

    }
    return(
        <div className="p-4 mt-5 mb-5">
            <h2 className="text-primary mb-5" style={{fontSize:"50px",fontWeight:"700"
            }}>Our Affiliates/Partners</h2>
            <hr />
            <Slider {...settings}>
                {data.map((d) => (
                <div>
                
                    <div className="text-center justify-content">
                        <p className="pname">{d.name}</p>
                        <img src={d.img} alt="partners and affiliates" className="bg-white" style={{width: "60%", padding:"5px", margin:"auto"}} />
                    </div>
                
                </div>
        
                ))}
            </Slider>
            <hr />
        </div>
    );
}
const data = [
    {
        name: "National Assoc. of Travel Agents",
        img: "/images/nanta.png",
    },
    {
        name: "Internation Air Travel Association",
        img: "/images/iata-logo1.png",
    },
    {
        name: "American Society of Travel Agents",
        img: "",
    },
    {
        name: "Nigeria Civil Aviation Authority",
        img: "/images/ncaa-logo1.png",
    }
];