import Bola from '../images/bola.jpg';
import Chidi from '../images/chidi2.jpg';
import Adeolu from '../images/adeolu.jpg';
import Avatar from '../images/avatar2.jpg';

export default function Team(){
    return(
        <div className="p-3 pl-5 pr-5 pb-5 team">
            <p>Our Team Members</p>
            <h2 className="text-center">Passionate Personalities,<br /><span style={{color: "blue"}}>Versatile</span> Brains</h2>
            
            <div className="row">

                <div className="col-sm-3 mt-3">
                    <div className="team-box boxshadow">
                        <div>
                            <img src={Avatar} alt="team member" width="100%" className="image" />
                            <div className="p-3">
                                <h5 className="text-center team-name">Chudi Aligwekwe</h5>
                                <h6 className="text-primary text-center">CEO</h6>
                            </div>
                            
                        </div>

                        <div class="overlay boxshadow">
                            <div class="text text-center pt-2 m-3">
                                <p className="mb-5 ml-5 mr-5 pt-3 text-white">Chudi is a seasoned travel expert with about a decade experience in the industry. He is very passionate about his job and clients.</p>
                                <button type="button" className="p-1 mb-5 text-center">Learn More</button>
                                <h5 className="text-center team-name pt-5 text-dark">Chudi Aligwekwe</h5>
                                <h6 className="text-primary text-center">Chief Executive Officer</h6>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-sm-3">
                    <div className="team-box mt-3 boxshadow">
                        <div>
                            <img src={Chidi} alt="team member" width="100%" className="image" />
                            <div className="p-3">
                                <h5 className="text-center team-name">Chidi Aloxie</h5>
                                <h6 className="text-primary text-center">Operation Manager</h6>
                            </div>
                            
                        </div>

                        <div class="overlay boxshadow">
                            <div class="text text-center pt-2 m-3">
                                <p className="mb-3 ml-5 mr-5  pt-3 text-white">Chidi is a seasoned travel expert with about a decade experience in the industry. He is very passionate about his job and clients.</p>
                                <button type="button" className="p-1 mb-5 text-center">Learn More</button>
                                <h5 className="text-center team-name pt-5 text-dark">Chidi Aloxie</h5>
                                <h6 className="text-primary text-center">Operation Manager</h6>
                                <p>08033356162<br />chidi@travelsolutionsltdng.com</p>  
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-sm-3">
                    <div className="team-box mt-3 boxshadow">
                        <div>
                            <img src={Adeolu} alt="team member" width="100%" className="image" />
                            <div className="p-3">
                                <h5 className="text-center team-name">Adeolu Ademoye</h5>
                                <h6 className="text-primary text-center">Operation Manager</h6>
                            </div>
                            
                        </div>

                        <div class="overlay boxshadow">
                            <div class="text text-center pt-2 m-3">
                                <p className="mb-3 ml-5 mr-5 pt-3 text-white">Adeolu is a seasoned travel expert with about a decade experience in the industry. He is very passionate about his job and clients.</p>
                                <button type="button" className="p-1 mb-5 text-center">Learn More</button>
                                <h5 className="text-center team-name pt-5 text-dark">Adeolu Ademoye</h5>
                                <h6 className="text-primary text-center">Operation Manager</h6>
                                <p>07038111706<br />deolu@travelsolutionsltdng.com</p> 
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-sm-3">
                    <div className="team-box mt-3 boxshadow">
                        <div>
                            <img src={Bola} alt="team member" width="100%" className="image" />
                            <div className="p-3">
                                <h5 className="text-center team-name">Bola</h5>
                                <h6 className="text-primary text-center">Accountant</h6>
                            </div>
                            
                        </div>

                        <div class="overlay boxshadow">
                            <div class="text text-center pt-2 m-3">
                                <p className="mb-5 ml-5 mr-5 pt-3 text-white">Bola is a professional accountant. He has put in many years of experience travel/aviation industry. He is very passionate about his job and clients.</p>
                                <button type="button" className="p-1 mb-5 text-center">Learn More</button>
                                <h5 className="text-center team-name pt-5 text-dark">Bola</h5>
                                <h6 className="text-primary text-center">Accountant</h6>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>

    );
}