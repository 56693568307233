import Menu from './menu.js';
import Office from '../images/vacations.jpg';
import Office2 from '../images/airport.png';
import Office3 from '../images/man1.jpg';
import Office4 from '../images/passport2.jpg';
import Office5 from '../images/family2.jpg';
import Checkin from '../images/checkingin8.jpg';
import Slider from "react-slick";
import { Link } from 'react-router-dom';

function Header(){
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000
    }

    return(
        <div className="main-header">
            
            <div className="row ml-5">
                <div className="col-sm-2">

                </div>
                <div className="col-sm-6">
                    Welcome to the world of Travels!
                </div>

                <div className="col-sm-4">
                    <span className="text-white text-right">support@travelsolutionsltdng.com</span>
                </div>
            </div>

            <div className="text-center m-3">
                <Menu />
            </div>

            <div className="row m-3">
                <div className="col-sm-6">
                    <p style={{color:"blue", fontSize:"20px", fontWeight:"600", padding: "20px 10px 0px 50px"}}>Concerning your Travels you are in the right place</p>
                    <h2 style={{fontSize: "65px", fontWeight: "700", padding: "10px 10px 0px 50px"}}>Best <span style={{color: "blue"}}>Travel Solutions</span> Ever!</h2>

                    <p style={{color:"black", fontSize:"30px", fontWeight:"600", padding: "10px 10px 0px 50px"}}>Get your best and affordable travel options</p>
                    
                    <div style={{color:"black", fontSize:"30px", fontWeight:"600", padding: "0px 10px 0px 50px"}}>
                    <Link class="btn btn-primary p-3 m-2"> About Us </Link>
                    <button class="btn btn-dark p-3 m-2" type="button"> Contact Us </button>
                    </div>
                </div>
                
                <div className="col-sm-6">
                    
                    <Slider {...settings}>
                        <div>
                            <img src={Office} alt="main banner" width="100%" className="banner boxshadow" style={{borderRadius: "5%", border: "12px solid white"}} />
                        </div>
                        <div>
                            <img src={Office2} alt="main banner" width="100%" className="banner boxshadow" style={{borderRadius: "5%", border: "12px solid lightblue"}} />
                        </div>
                        <div>
                            <img src={Office3} alt="main banner" width="100%" className="banner boxshadow" style={{borderRadius: "5%", border: "12px solid white"}} />
                        </div>
                        <div>
                            <img src={Office4} alt="main banner" width="100%" className="banner boxshadow" style={{borderRadius: "5%", border: "12px solid white"}} />
                        </div>
                        <div>
                            <img src={Office5} alt="main banner" width="100%" className="banner boxshadow" style={{borderRadius: "5%", border: "12px solid white"}} />
                        </div>

                        <div>
                            <img src={Checkin} alt="main banner" width="100%" className="banner boxshadow" style={{borderRadius: "5%", border: "12px solid white"}} />
                        </div>
                        
                    </Slider>
                </div>
            </div>

        </div>
    );
}
export default Header;