import { Link } from 'react-router-dom';

export default function Footer(){
    return(
        <div className="footer-main">
            <div className="row mb-5 p-5 footer-main-inner-div">
                <div className="col-sm-3">
                    <h3>Travel Solutions</h3>
                    <p>
                        We are one of the foremost Travel Agencies in Nigeria. Our services span across the borders of this nation.
                    </p>
                    <ul>
                        <li>
                            <span>Open Hours:</span>
                            <h5>Mon – Fri: 9 am – 5 pm</h5>
                            <h5>Sunday: CLOSED</h5>
                        </li>
                    </ul>
                </div>

                <div className="col-sm-3">
                    <h3>Help Links</h3>
                    <nav>
                        <ul>
                            <li>
                                <Link to="/contact">Customer Support</Link>
                            </li>
                            <li>
                                <Link to="/about">About Us</Link>
                            </li>
                            <li>
                                <Link to="/">Make Enquiries</Link>
                            </li>
                            <li>
                                <Link to="/">Make an Appointment</Link>
                            </li>
                            <li>
                                <Link to="/services">Our Services</Link>
                            </li>
                            <li>
                                <Link to="/blogs">Blogs</Link>
                            </li>
                            <li>
                                <Link to="/contact">Contact Us</Link>
                            </li>
                        </ul>
                    </nav>
                </div>

                <div className="col-sm-3">
                    <h3>Official Info</h3>
                    <ul>
                        <li>
                            <span>Voice Call</span>
                            <h5>+234 803 304 3856</h5>
                        </li>
                        <li>
                            <span>Send us email</span>
                            <h5>info@travelsolutionsltdng.com</h5>
                        </li>
                        <li>
                            <span>Our Address</span>
                            <h5>60, Ilupeju Way, Dolphin Estate, Ikoyi, Lagos, Nigeria.</h5>
                        </li>
                    </ul>
                </div>

                <div className="col-sm-3">
                    <h3>Follow Us On:</h3>
                    <ul>
                        <li>Facebook</li>
                        <li>Instagram</li>
                        <li>X</li>
                        <li>Linkedin</li>
                        <li>Whatsapp</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}